import React from "react";
import "./Loader.css";
import imageSrc from '../../Assets/Images/Navbar/logoonly.jpg';

const Loader = () => {
  return (
    <div className="loader-container">
      <span className="loader"></span>
      <img src={imageSrc} className="loader-logo" />
    </div>
  );
};

export default Loader;
