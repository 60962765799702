import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
    name: "common",
    initialState: {
        navbar_render: false,
    },
    reducers: {
        updateNavbarRender: (state, actions) => {
            state.navbar_render = !state.navbar_render
        },
    }
})

export const { updateNavbarRender } = commonSlice.actions

export default commonSlice.reducer