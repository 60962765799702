import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { IntlProvider } from "react-intl";
import { Provider } from 'react-redux';
import rootStore from './Store/rootStore';

import Loader from "./Components/Common/Loader";

import "./Assets/Styles/Navbar.css";
import './Assets/Styles/Lang.css'
import "./Assets/Styles/Landing.css";
import "./Assets/Styles/Slider.css";
import "./Assets/Styles/Product.css";
import "./Assets/Styles/Project.css";
import "./Assets/Styles/Contact.css";
import "./Assets/Styles/About.css";
import "./Assets/Styles/Service.css";
import "./Assets/Styles/Footer.css";
import './Assets/Styles/Sidebar.css'
import './Assets/Styles/DashNav.css'
import './Assets/Styles/Signup.css'
import './Assets/Styles/Common.css'

import messages from "./locales/messages.json";

const locale = localStorage.getItem("language") == "ar" ? "ar" : "en";

const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={rootStore}>
  <IntlProvider locale={locale} messages={messages[locale]}>
  <Suspense fallback={<Loader />}>
    <Toaster
      position="top-right" // or "bottom-right"
      toastOptions={{
        style: {
          fontSize: "20px",
          padding: "20px",
          borderRadius: "10px",
        },
      }}
    />
    <App />
  </Suspense>
</IntlProvider>
</Provider>
);
